import { datadogRum } from '@datadog/browser-rum';
import { fabric } from 'fabric';
import { TFunction } from 'i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { setIsToasterOpen } from '../context/app-context';
import { Dispatch } from '../context/app-context/app-context-types';
import { CardContextState } from '../context/card-context';
import {
  CanvasJson,
  CardFace,
  CardFaceData,
  CardText,
  CardType,
  CustomFabricImage,
  DEFAULT_PROJECT_NAMES,
  FabricObject,
  FabricTextBox,
  ObjectJson,
  ProjectTypeCode,
  TemplateData,
} from '../global-types';
import { CanvasDataTypes } from '../utils/canvas-utils';
import { helperSettingsConfig } from './configs/helper-settings.configs';
import { getCanvasJson, getCurrentCanvas } from './helper-settings/index';
import { scaleCanvasObjects } from './helper-settings/scale-canvas-objects';
import { expandText, getMaxWidthLine, mergeLines, MIN_FONT_SIZE, shrinkText, validateTransformedData } from './index';

export const getMaxTextLines = (cardText: CardText): number => {
  const numberOfNewLines = (cardText.Text.match(/\n/g) || []).length;
  const maxLines = cardText.IsMultiline ? numberOfNewLines + 1 : 1;
  return maxLines;
};

export const mergeAndShrinkText = (tb: FabricTextBox, canvas, appDispatch, t) => {
  const { fixedWidth, maxFontSize, maxLines } = tb.data;
  // when the user tries to add multiple lines
  if (tb.textLines.length <= maxLines && getMaxWidthLine(tb) < fixedWidth) {
    while (
      tb.textLines.length <= maxLines &&
      tb.fontSize &&
      tb.fontSize < maxFontSize &&
      getMaxWidthLine(tb) < fixedWidth
    ) {
      expandText(tb, maxFontSize);
    }
  }

  if (tb.textLines.length > 1) {
    // by enter
    if ((tb.text as string).indexOf('\n') !== -1) {
      mergeLines(tb);
      // this.activeCanvas?.renderAll();
      tb.render(canvas?.current?.getContext() as CanvasRenderingContext2D);
    }

    // by long ass yee yee text
    while (tb.textLines.length > 1) {
      shrinkText(tb);
      if (tb.fontSize === MIN_FONT_SIZE && (tb.textLines.length > maxLines || getMaxWidthLine(tb) > fixedWidth)) {
        const textLength = tb.text?.length || 0;
        const offset = tb.selectionEnd || textLength;
        tb.removeChars(offset - 1, offset);
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Warning,
          children: t('editableText.toastWarningDescription'),
          title: t('editableText.toastWarningTitle'),
        });
        if (tb.hiddenTextarea) {
          tb.hiddenTextarea.value = tb.text as string;
        }
        tb.setSelectionStart(offset - 1);
        tb.setSelectionEnd(offset - 1);
        tb.width = fixedWidth;
      } else {
        shrinkText(tb);
        break;
      }
    }
  }
  tb.width = fixedWidth;
};

export const clipObject = (zone: fabric.Group, item: fabric.Object) => {
  item.clipPath = new fabric.Rect({
    width: zone.width,
    height: zone.height,
    left: zone.left,
    top: zone.top,
    angle: zone.angle,
    absolutePositioned: true,
  });
};

export const bleedInPixels = () => {
  const { scalingFactor } = helperSettingsConfig;
  return bleedInPixelsNoScale() / scalingFactor;
};

export const bleedInPixelsNoScale = () => {
  const { bleedInMM, mmInPixels } = helperSettingsConfig;
  return bleedInMM * mmInPixels;
};

// deep copy for objects and arrays
export function deepCopy(inObject: any) {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject;
  }

  const outObject: any = Array.isArray(inObject) ? [] : {};

  Object.keys(inObject).forEach((key) => {
    const val = inObject[`${key}`];
    outObject[`${key}`] = deepCopy(val);
  });

  return outObject;
}

export const getBleed = ({ projectTypeCode, cardState, faceType }) => {
  const { cardType, isPostcard, isPostcardFromPhoto, isChocolate } = cardState;
  const isCardTypeSAS = projectTypeCode === CardType.SAS;
  const isCardTypePhoto = cardType === 'photo';
  const isCardFaceFront = faceType === 'front';
  const isCardTypeSASOrChocolate = isCardTypeSAS || isChocolate;
  const isCardTypePhotoANDCardFaceFront = isCardTypePhoto && isCardFaceFront;
  const isPostcardFromPhotoANDCardFaceFront = isPostcardFromPhoto && isCardFaceFront;

  return isCardTypeSASOrChocolate ||
    isCardTypePhotoANDCardFaceFront ||
    (isPostcard && !isPostcardFromPhoto) ||
    isPostcardFromPhotoANDCardFaceFront
    ? 0
    : bleedInPixels();
};

// the defaults for the overlay images used on photocards (the photoframe)
export const overlayImageDefaultSettings = {
  name: 'overlayImg',
  evented: false,
  selectable: false,
};

// overlay image (photoframe) settings
export function getOverlayImageSettings(cardState: CardContextState) {
  const bleedPx = cardState.isChocolate ? 0 : bleedInPixels();

  return Object.assign(deepCopy(overlayImageDefaultSettings), {
    left: Math.ceil(-bleedPx),
    top: Math.ceil(-bleedPx),
  });
}

/**
 * Building the correct background url with the desired width included
 * @param background
 * @param width
 * @returns background url
 */
const buildBackgroundSrc = (background, width) => {
  if (!background || background == '' || !width) {
    throw new Error('Invalid background image SRC');
  }
  return `${background}?w=${width}`;
};

/**
 * Get the replacement background url
 * the returned value is checked in createPrintJson and only switches if needed
 * @param cardType
 * @param face
 * @param width
 * @returns replacement background url when needed, if not needed, the function returns null
 */
const getReplacementBackground = (cardType, face, width) => {
  switch (cardType) {
    case 'chocolate':
      return buildBackgroundSrc(face.chocolateBackground, width);
    case 'chocophoto':
      if (face.type === 'back') {
        return buildBackgroundSrc(face.chocolateBackground, width);
      }

      break;
    case 'wooden':
      return buildBackgroundSrc(face.woodenBackground, width);
    case 'woodenphoto':
      if (face.type === 'back') {
        return buildBackgroundSrc(face.woodenBackground, width);
      }

      break;
    default:
      return null;
  }

  return null;
};

export const removePhotoTextZonesFromCanvas = (canvas: fabric.Canvas) => {
  const canvasObjects = canvas._objects;
  const photoTextZones = canvasObjects.filter((obj) => obj.type === 'group') as fabric.Group[];
  if (photoTextZones.length <= 0) {
    return canvas;
  }

  photoTextZones.forEach((zone) => {
    const groupedZone = zone._objects.find((obj) => obj.type === 'textbox');
    canvas.remove(zone);
    if (!groupedZone || !zone.top || !zone.height) {
      return;
    }
    canvas.add(groupedZone);
    groupedZone.set({
      top: zone.top - zone.height / 2,
      left: zone.left,
      width: zone.width,
      height: zone.height,
      originY: 'top',
      originX: zone.originX,
      dirty: true,
      data: {
        ...groupedZone.data,
        ungroupedText: true,
        // we use this to set the original position after restoring S&S canvas objects
        originalData: {
          top: groupedZone.top,
          left: groupedZone.left,
          width: groupedZone.width,
          height: groupedZone.height,
          originY: groupedZone.originY,
          originX: groupedZone.originX,
        },
      },
    });
  });

  return canvas;
};

/**
 * Mimics functionality of removePhotoTextZonesFromCanvas, except on
 * JSON objects instead of directly on the canvas.
 * Then removes the photo-text-zones objects and adds the extracted text-boxes to it.
 * Same functionality as removePhotoTextZonesFromCanvas but instead of using the current canvas,
 * it uses the JSON representation.
 *
 * @param canvasJson JSON representation of the canvas
 * @returns
 */
const removeGroupsFromCanvasJson = (jsonObjects: ObjectJson[]) => {
  const photoTextZonesJson = jsonObjects.filter((obj) => obj.type === 'group');
  const filteredJsonObjects = jsonObjects.filter((obj) => obj.type !== 'group');
  const groupedZones = photoTextZonesJson.filter((zone) => {
    const savedObjects = zone.objects.find((obj) => obj.type === 'textbox');
    if (!savedObjects || !zone.top || !zone.height) {
      return;
    }
    const groupedZone = {
      ...savedObjects,
      top: zone.top - zone.height / 2,
      left: zone.left,
      width: zone.width,
      height: zone.height,
      originY: 'top',
      originX: zone.originX,
      dirty: true,
      data: {
        ...savedObjects.data,
        ungroupedText: true,
        // we use this to set the original position after restoring S&S canvas objects
        originalData: {
          top: savedObjects.top,
          left: savedObjects.left,
          width: savedObjects.width,
          height: savedObjects.height,
          originY: savedObjects.originY,
          originX: savedObjects.originX,
        },
      },
    };
    return groupedZone;
  });

  return [...filteredJsonObjects, ...groupedZones];
};

/**
 * Extracts the textbox from photo-text-zones objects in the canvasJSON.
 * Then removes the photo-text-zones objects and adds the extracted text-boxes to it.
 * Same functionality as removePhotoTextZonesFromCanvas but instead of using the current canvas,
 * it uses the JSON representation.
 *
 * @param canvasJson JSON representation of the canvas
 * @returns
 */
export const removePhotoTextZonesFromCanvasJson = (canvasJson: CanvasJson) => {
  const filteredObjects = removeGroupsFromCanvasJson(canvasJson.objects);
  const backgroundImage = canvasJson.backgroundImage;
  const ungroupedCanvasJson: CanvasJson = {
    objects: filteredObjects.filter((obj) => !obj.name?.startsWith(CanvasDataTypes.PhotoTextZone)),
    version: canvasJson.version,
    backgroundImage,
  };
  const photoTextZones = canvasJson.objects.filter((obj) => obj.name?.startsWith(CanvasDataTypes.PhotoTextZone));
  photoTextZones.forEach((zone) => {
    const { scalingFactor } = helperSettingsConfig;
    const { top = 0, left = 0, width = 0, height = 0, originX } = zone;
    const textbox = zone.objects.find((obj) => obj.type === 'textbox');
    if (textbox && isTextbox(textbox)) {
      const { fontSize = 0 } = textbox;
      Object.assign(textbox, {
        top: top - height,
        left: left,
        width: width * scalingFactor,
        height: height * scalingFactor,
        fontSize: fontSize * scalingFactor,
        dirty: true,
        originY: 'top',
        originX,
      });
      if (textbox.clipPath) {
        const { top = 0, left = 0 } = textbox.clipPath;
        Object.assign(textbox.clipPath, {
          left: left * scalingFactor,
          top: top * scalingFactor,
          scaleX: scalingFactor,
          scaleY: scalingFactor,
        });
      }
      ungroupedCanvasJson.objects.push(textbox);
    }
  });
  return ungroupedCanvasJson;
};

/**
 *
 * @param object fabric object of which you want to know if it is a zone button
 * @returns true if object is either an editable-text button or a photo-zone button
 */
export const isZoneButton = (object: fabric.Object) => {
  const zoneButtonTypes = [CanvasDataTypes.EditableTextButton, CanvasDataTypes.PhotoZoneButton];
  return object.data && zoneButtonTypes.includes(object.data.type);
};

/**
 *
 * @param canvasObj
 */
export const removeZonesButtons = (canvasObj: CanvasJson) => {
  if (canvasObj && canvasObj.objects && canvasObj.objects.length > 0) {
    canvasObj.objects = canvasObj.objects.filter((obj) => !isZoneButton(obj));
  }
};

/**
 * Function to remove all the zone buttons and photo-text zones from the canvas json object, in order to send a sanitized object to the BE
 *
 * @param canvasObj object representing canvas from which you want to remove the zones buttons
 * @returns sanitized canvas object without the zones buttons and photo-text-zones
 */
export const sanitizeCanvasJson = (canvasObj: CanvasJson) => {
  const canvasJSON = removePhotoTextZonesFromCanvasJson(canvasObj);
  removeZonesButtons(canvasJSON);
  return canvasJSON;
};

/**
 * hiding the background image for the cases checked below
 * showing the background image otherwise
 * @param cardType
 * @param faceType
 * @returns true or false for showing image background
 */
const showBackgroundImage = (cardType: string, faceType: string) => {
  const isPhotoType = ['photo', 'woodenphoto', 'chocophoto', 'vederephoto'].includes(cardType);
  const isVedereType = cardType === 'vedere' || cardType === 'vederephoto';

  return faceType === 'front' ? !isPhotoType : faceType === 'back' ? !isVedereType : true;
};

/**
 *
 * @param object fabric object to check if it is a textbox or not
 * @returns true if the type of the object is 'textbox'
 */
export const isTextbox = (object: FabricObject): object is fabric.Textbox => object.type === 'textbox';

/**
 *
 * @param object fabric object to check if it is an image or not
 * @returns true if the type of the object is 'image'
 */
export const isImage = (object: FabricObject): object is CustomFabricImage => object.type === 'image';

/**
 * called when the print json is created or when the card is saved
 * @param canvas
 * @param isChocolate
 * @returns transformed print json
 */
export const removeCanvasScalingFactor = (canvasJson: CanvasJson, isChocolate: boolean, projectTypeCode: string) => {
  const noBleed = isChocolate || projectTypeCode === CardType.SAS;
  const { scalingFactor } = helperSettingsConfig;
  const sCanvas = { ...canvasJson };

  // on chocolate cards the bleed wasn't removed on load in order to see the chocolate border
  // so we do not need to add it back to the card
  const bleed = noBleed ? 0 : bleedInPixels();

  if (sCanvas.backgroundImage) {
    const { width, height, scaleX, scaleY } = sCanvas.backgroundImage;
    if (width && height && scaleX && scaleY) {
      sCanvas.backgroundImage.width = (width - bleed * scalingFactor) * scalingFactor * scaleX;
      sCanvas.backgroundImage.height = (height - bleed * scalingFactor) * scalingFactor * scaleY;
    }
    sCanvas.backgroundImage.left = 0;
    sCanvas.backgroundImage.top = 0;
    const imgSrc = sCanvas.backgroundImage.src?.split('?w=')[0];
    sCanvas.backgroundImage.src = `${imgSrc}?w=${sCanvas.backgroundImage.width}`;
  }

  sCanvas.objects = scaleCanvasObjects(sCanvas, bleed, scalingFactor);
  return sCanvas;
};

/**
 * Get the user images
 * @param face
 * @returns user images
 */
const getUserImages = (face: CardFaceData) => {
  const photosAddedOnFace: string[] = [];
  face.canvas?.current?.getObjects().forEach((obj) => {
    if (obj.name && (obj.data?.type === 'userUploadedImage' || obj.data?.type === CanvasDataTypes.UserImage)) {
      photosAddedOnFace.push(obj.name);
    }
  });
  return photosAddedOnFace;
};

export const cleanPlaceholders = (canvas: fabric.Canvas) => {
  const placeholders = canvas
    ?.getObjects()
    .filter((obj) => obj.data?.type === CanvasDataTypes.Placeholder) as fabric.Textbox[];
  placeholders &&
    placeholders.forEach((placeholder) => {
      if (!placeholder.data?.edited) {
        canvas.remove(placeholder);
      }
    });
};

export const cleanFoldLines = (canvas: fabric.Canvas) => {
  const foldLines = canvas?.getObjects().filter((obj) => obj.data?.type === CanvasDataTypes.FoldLine) as fabric.Rect[];
  foldLines &&
    foldLines.forEach((foldline) => {
      canvas.remove(foldline);
    });
};

export const cleanUneditedTexts = (canvas: fabric.Canvas) => {
  const uneditedTexts = canvas
    ?.getObjects('textbox')
    .filter((obj) => obj.data?.type === CanvasDataTypes.UserText && !obj.data?.isEdited);

  uneditedTexts && uneditedTexts.forEach((text) => canvas.remove(text));
};

export type TransformedPersonalizationData = {
  FaceId: number;
  FaceNumber: number;
  CanvasJson: CanvasJson | null | undefined;
  PrintJson: CanvasJson;
  ImagePreview: string;
  UserImages: string[];
};

export const createCanvasJson = (
  propertiesToInclude: string[],
  cardFace: CardFaceData,
  isChocolate: boolean,
  projectTypeCode: ProjectTypeCode,
): CanvasJson | null => {
  let canvasJson = (
    getCurrentCanvas(cardFace) ? getCanvasJson(cardFace, propertiesToInclude) : null
  ) as CanvasJson | null;
  if (canvasJson) {
    canvasJson = canvasJson ? removeCanvasScalingFactor(canvasJson, isChocolate, projectTypeCode) : null;
  }

  return canvasJson;
};

/**
 * creates the json that will be sent to the printer
 * @param cardFace
 * @param isChocolate
 * @param cardType
 * @returns json that will be sent to the printer
 */
const createPrintJson = (
  cardFace: CardFaceData,
  isChocolate: boolean,
  cardType: string,
  projectTypeCode: ProjectTypeCode,
): CanvasJson | null => {
  const currentCanvas = cardFace.canvas?.current;
  const canvasJSON = currentCanvas
    ? currentCanvas.toJSON(['name', 'data'])
    : cardFace.originalCanvasJson ?? cardFace.printJson;

  if (!canvasJSON) {
    return null;
  }

  const toPrint = currentCanvas
    ? removePhotoTextZonesFromCanvasJson(removeCanvasScalingFactor(canvasJSON, isChocolate, projectTypeCode))
    : sanitizeCanvasJson(canvasJSON);

  // we get the replacement background (if needed) at the desired width
  const bgWidth = (toPrint.backgroundImage as CustomFabricImage).width;
  const replacementBackground = getReplacementBackground(cardType, cardFace, bgWidth);

  if (replacementBackground && replacementBackground !== '') {
    (toPrint.backgroundImage as CustomFabricImage).src = replacementBackground;
  }

  // we show or hide the background image depending on the card type and the face we're processing
  (toPrint.backgroundImage as CustomFabricImage).visible = showBackgroundImage(cardType, cardFace.type);
  (toPrint.backgroundImage as CustomFabricImage).scaleX = 1;
  (toPrint.backgroundImage as CustomFabricImage).scaleY = 1;

  return toPrint;
};

/**
 * Get the transformed personalization data to be sent to BE
 *
 * @param cardState current state of card-context
 * @param projectTypeCode type of the loaded project
 * @param templateData template data to validate the print json objects count, this is optional since we want to use it when are going to save the project.
 * @returns
 */
export const getTransformedPersonalizationData = async (
  cardState: CardContextState,
  projectTypeCode: ProjectTypeCode,
  isOneToMany: boolean, // TODO: remove this unused param
  templateData?: TemplateData,
): Promise<TransformedPersonalizationData[]> => {
  const { cardType, cardFacesList, isChocolate } = cardState;
  const cardFaces = cardFacesList;
  cardFaces.forEach((cardFace: CardFaceData) => {
    if (cardFace.canvas.current) {
      cleanPlaceholders(cardFace.canvas.current);
      cleanFoldLines(cardFace.canvas.current);
      cleanUneditedTexts(cardFace.canvas.current);
    }
  });

  datadogRum.addAction('generating-personalization-data', {
    projectId: getProjectId(),
    productTypeCode: getProductTypeCode(),
    cardFacesData: cardFacesList,
  });

  try {
    return cardFaces.map((cardFace: CardFaceData) => {
      const templateCardFace = templateData?.Faces.find(
        (templateCardFace) => templateCardFace.FaceId === cardFace.faceId,
      );
      if (templateData && validateTransformedData(cardFace, templateCardFace as CardFace)) {
        throw new Error('The current json has less objects than the template json');
      }
      return {
        FaceId: cardFace.faceId,
        FaceNumber: cardFace.faceNumber,
        CanvasJson: createCanvasJson(
          [
            'imgToZoneId',
            'editableText',
            'isModified',
            'name',
            'editable',
            'lockScalingFlip',
            'lockSkewingX',
            'minScaleLimit',
            'selectable',
            'zoneId',
            'hasImg',
            'lockMovementX',
            'lockMovementY',
            'data',
            'maxHeight',
            'selectionStart',
            'selectionEnd',
            'cornerColor',
            'editingBorderColor',
            'selectionColor',
            'textBackgroundColor',
            'borderDashArray',
            'oCoords',
            'evented',
            'padding',
            'objectCaching',
            'btnId',
            'fixedWidth',
            'breakpoints',
            'singleLine',
            'maxFontSize',
            'hoverCursor',
            'lockRotation',
            'hasControls',
            'hasRotatingPoint',
          ],
          cardFace,
          isChocolate,
          projectTypeCode,
        ),
        PrintJson: createPrintJson(cardFace, isChocolate, cardType, projectTypeCode),
        ImagePreview: cardFace.backgroundImage,
        UserImages: getUserImages(cardFace),
      } as TransformedPersonalizationData;
    });
  } catch (error: any) {
    throw Error(error);
  }
};

export const getProductTypeCode = (): string => {
  return new URLSearchParams(window.location.search).get('product_type_code') || '';
};

export const getProjectName = (): string => {
  const productTypeCode = getProductTypeCode();
  return DEFAULT_PROJECT_NAMES[`${productTypeCode}`] ?? '';
};

export const getProductId = (): string => {
  if (window.location.href.includes('create')) {
    const urlArray = window.location.href.split('?')[0].split('/');
    const createIndex = urlArray.findIndex((currentIndex) => currentIndex === 'create');
    return urlArray[createIndex + 1];
  }
  return '';
};

export const getProjectId = (): string => {
  if (window.location.href.includes('edit')) {
    const urlArray = window.location.href.split('?')[0].split('/');
    const editIndex = urlArray.findIndex((currentIndex) => currentIndex === 'edit');
    return urlArray[editIndex + 1];
  }
  return '';
};

export const jsonToQueryString = (json: Record<string, string | number | boolean>) => {
  return (
    '?' +
    Object.keys(json)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[`${key}`]);
      })
      .join('&')
  );
};

export const tamFitsInZone = (textbox: fabric.Textbox, photoTextZone: fabric.Group): boolean => {
  const height = textbox.getScaledHeight();
  const width = textbox.getScaledWidth();
  return height <= photoTextZone.getScaledHeight() && width <= photoTextZone.getScaledWidth();
};

export const toggleTamWarning = (
  textbox: fabric.Textbox,
  photoTextZone: fabric.Group,
  appDispatch: Dispatch,
  translate: TFunction,
) => {
  if (tamFitsInZone(textbox, photoTextZone)) {
    photoTextZone.data.warned = false;
    return;
  }
  if (!photoTextZone.data.warned) {
    photoTextZone.data.warned = true;
    setIsToasterOpen(appDispatch, {
      title: translate('typeMessage.warningTitle'),
      children: translate('typeMessage.warningMessage'),
      variant: ToastVariants.Warning,
    });
  }
};

/**
 * Loop through canvas objects and remove edit icons
 *
 * @param canvas canvas from which to remove icons
 * @returns void
 */
export const removeEditIconsFromCanvas = (canvas) => {
  if (canvas) {
    const objs = canvas.getObjects();
    objs.forEach((el) => {
      if (el.name === 'edit-icon') {
        canvas.remove(el);
      }
    });
  }
};
