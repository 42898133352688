const DELETE_ICON_TOP_OFFSET = 114;
const TEXT_BOX_LEFT_OFFSET_PORTRAIT = 60;
const TEXT_BOX_RIGHT_OFFSET_PORTRAIT = 54;
const TEXT_BOX_TOP_OFFSET_PORTRAIT = 18;
const TEXT_BOX_LEFT_OFFSET_LANDSCAPE = 40;
const TEXT_BOX_RIGHT_OFFSET_LANDSCAPE = 38;
const TEXT_BOX_TOP_OFFSET_LANDSCAPE = -22;

export {
  DELETE_ICON_TOP_OFFSET,
  TEXT_BOX_LEFT_OFFSET_PORTRAIT,
  TEXT_BOX_RIGHT_OFFSET_PORTRAIT,
  TEXT_BOX_TOP_OFFSET_PORTRAIT,
  TEXT_BOX_TOP_OFFSET_LANDSCAPE,
  TEXT_BOX_LEFT_OFFSET_LANDSCAPE,
  TEXT_BOX_RIGHT_OFFSET_LANDSCAPE,
};
