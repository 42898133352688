import React, { lazy, Suspense, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@hallmark/web.core.buttons.button';
import { RadioCard } from '@hallmark/web.core.forms.radio-card';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAnalyticsContext } from '../../context/analytics-context';
import { setIsSendToMe, useAppContext } from '../../context/app-context';
import { updateAddresses, useInitializationDataContext } from '../../context/data-context';
import { Address, AddressForm as AddressFormType, AddressTypes, FormFieldAddress } from '../../global-types/addresses';
import { useFormFieldGenerator } from '../../hooks/use-form-field-generator';
import { updateProject } from '../../services/customization';
import { buildOneToOneAddresses } from '../../utils/build-one-to-one-addresses';
import { useEnvelopeSelection } from '../../views/select-envelope/hooks/use-envelope-selection';
import { Contact } from '../address-book';
import { EditAddressDialog } from '../edit-address-dialog/edit-address-dialog';
import { FormFieldsGenerator } from '../form-fields-generator/form-fields-generator';
import { RecipientAddressesDialog } from '../recipient-addresses-dialog/recipient-addresses-dialog';
import { RecipientAddressesVariant } from '../recipient-addresses-dialog/recipient-addresses-dialog-types';
import { AddressFormProps, DeliveryModes } from './address-form-types';
import styles from './address-form.module.scss';
import { getContactFormData } from './address-utils';
import { AddressBookButton } from './fragments/address-book-button';
import { AddressFormFooterButtons } from './fragments/address-form-footer-buttons';
import { Header } from './fragments/header';
import { useUpdateAddress } from './hooks/use-update-address';
import { addressFormFieldList } from './utils/address-form-field-list';

/**
 * Lazy Address Book since it's
 * only needed for US Address cards
 */
const QuickAddressDialogComponent = lazy(() =>
  import('../../components/quick-address-dialog-container/quick-address-dialog-container').then((module) => ({
    default: module.QuickAddressDialogContainer,
  })),
);

/**
 * Suspense fallback component
 */
const AddressBookLoader = () => <div data-testId="quick-address-dialog-loader">Loading</div>;

export const AddressForm = ({
  addressType,
  addClass,
  shouldRenderEnvelope,
  formHandlers,
  isLoadingAddresses,
  getNextPage,
  onSkipValidation,
  setSubmittedAddresses,
  setShouldSave,
  getAddressesProps,
  footerButtonsProps,
}: AddressFormProps): React.ReactElement => {
  const [isQuickAddressOpen, setIsQuickAddressOpen] = useState(false);

  const classes = classNames(styles['address-form-wrapper'], addClass);
  const { trackSelectedQuickAddress } = useAnalyticsContext();

  const { t } = useTranslation();
  const {
    initializedDataState: { data: initializedData, addressData, isUS, isUK },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const {
    appState: { isSendToMe },
    appDispatch,
  } = useAppContext();
  const { selectedEnvelope } = useEnvelopeSelection();

  const projectId = initializedData?.project_id;

  const isRecipientAddress = addressType === AddressTypes.RECIPIENT;
  const formTitle = isRecipientAddress ? `${t('addressForm.recipientTitle')}` : `${t('addressForm.returnTitle')}`;

  // form values for the address form
  const { getValues, setValue, register } = formHandlers;
  const {
    isOpen: isDialogOpen,
    setIsOpen: setRecipientAddressesDialogOpen,
    isLoading: isGetAddressesLoading,
  } = getAddressesProps;

  if (isSendToMe) {
    setValue('send_to', 'me');
    // If false, form defaults to 'them'
  }

  // Scroll address form to top when user moves betweeen recipient and return address
  useEffect(() => {
    document.querySelector('form')?.scrollIntoView();
  }, [formTitle]);

  const handleAddressBookSubmit = (newAddress: Contact) => {
    trackSelectedQuickAddress(formTitle);
    const addressFormValues = getValues();
    const contactFormData = getContactFormData(addressFormValues, newAddress);

    Object.keys(contactFormData).forEach((field) => {
      setValue(field as keyof Address, contactFormData[`${field}`], { shouldValidate: true });
    });

    // get the updated recipient address from the form
    const recipientAddress = getValues();
    // update submittedAddresses adding the new recipient address
    buildOneToOneAddresses(recipientAddress, setSubmittedAddresses);
    // update the initialization data state with the new recipient address
    const currentAddressType =
      recipientAddress.address_type_code === 'R' ? AddressTypes.RECIPIENT : AddressTypes.SENDER;
    updateAddresses(initializationDataDispatch, {
      [currentAddressType]: { ...addressData[`${currentAddressType}`], ...(recipientAddress as Address) },
    });
  };

  const handleDeliveryModeChange = () => {
    // Store send_to in state in case user leaves/returns
    const isSendToMeChecked = getValues('send_to') === 'me';
    const deliveryMode = isSendToMeChecked ? DeliveryModes.ShipToMe : DeliveryModes.MailToRecipient;
    const data = {
      delivery_mode: deliveryMode,
    };
    setIsSendToMe(appDispatch, isSendToMeChecked);
    updateProject(data, projectId);
  };

  const shouldHideAddressBook = shouldRenderEnvelope && selectedEnvelope === 'blank';

  const { generatedFormFields: selectedFormFields } = useFormFieldGenerator(
    formHandlers as unknown as UseFormReturn<FormFieldAddress>,
    'address',
    addressFormFieldList,
  );

  const handleOnOpenAddressBook = () => {
    setIsQuickAddressOpen(true);
  };
  const handleOnCloseAddressBook = () => {
    setIsQuickAddressOpen(false);
  };

  // values and handlers for the edit address dialog
  const {
    formValues,
    handleSubmitRecipientAddress,
    handleCloseEditAddress,
    dialogState: { openEditDialog, setOpenEditDialog },
  } = useUpdateAddress(setRecipientAddressesDialogOpen);
  const {
    handleSubmit,
    reset: resetRecipientAddressForm,
    formState: { isValid },
  } = formValues;

  return (
    <div className={classes}>
      <form className={styles['address-form']} data-testid="address-form">
        <div tabIndex={-1} className={styles['address-form-content']}>
          <Header register={register} formTitle={formTitle} />
          {isUK && (
            <div role="radiogroup" aria-labelledby="radio_button_group" className={styles['radio-cards-container']}>
              <RadioCard
                domId="send-to-me-radio-card"
                helperText="Send to Me"
                register={register('send_to', {
                  required: true,
                  onChange: handleDeliveryModeChange,
                })}
                addClass={styles['radio-card']}
                value="me"
              >
                Sent to you with a spare envelope
              </RadioCard>
              <RadioCard
                domId="send-to-them-radio-card"
                helperText="Send to Them"
                register={register('send_to', {
                  required: true,
                  onChange: handleDeliveryModeChange,
                })}
                addClass={styles['radio-card']}
                value="them"
              >
                Sent directly to the recipient
              </RadioCard>
            </div>
          )}
          {isUS && !shouldHideAddressBook && <AddressBookButton click={handleOnOpenAddressBook} />}
          <div className={classNames(selectedEnvelope === 'blank' && styles['hide-address'])}>
            <FormFieldsGenerator selectedFormFields={selectedFormFields} register={register} />
          </div>

          {!isGetAddressesLoading && isDialogOpen && (
            <RecipientAddressesDialog
              isOpen={isDialogOpen}
              resetRecipientAddressForm={resetRecipientAddressForm}
              setRecipientAddressesDialogOpen={setRecipientAddressesDialogOpen}
              setOpenEditDialog={setOpenEditDialog}
              variant={RecipientAddressesVariant.Address}
              onActionButtonClick={footerButtonsProps.oneToManyProps.primaryActionButton.onClick}
              onSecondaryActionButtonClick={() => setRecipientAddressesDialogOpen(false)}
            />
          )}

          {openEditDialog && (
            <EditAddressDialog
              title={t('editRecipientAddressDialog.title')}
              accentIcon={IconNames.Edit}
              isEditAddressVisible={openEditDialog}
              onCloseEditAddress={handleCloseEditAddress}
              formHandlers={formValues as unknown as UseFormReturn<AddressFormType>}
              actionButton={
                <Button
                  testId={'edit-recipient-address-dialog-update-button'}
                  click={handleSubmit(handleSubmitRecipientAddress)}
                  disabled={!isValid}
                >
                  {t('editRecipientAddressDialog.actionButton')}
                </Button>
              }
            />
          )}
          <AddressFormFooterButtons
            oneToOneProps={footerButtonsProps.oneToOneProps}
            oneToManyProps={footerButtonsProps.oneToManyProps}
          />
        </div>
      </form>
      {isUS && isQuickAddressOpen && (
        <div data-testid={'quick-address-dialog-container'}>
          <Suspense fallback={<AddressBookLoader />}>
            <QuickAddressDialogComponent
              getNextPage={getNextPage}
              formTitle={formTitle}
              handleAddressBookSubmit={handleAddressBookSubmit}
              isAddressBookOpen={isQuickAddressOpen}
              isLoadingAddresses={isLoadingAddresses}
              onCloseAddressBook={handleOnCloseAddressBook}
              onSkipValidation={onSkipValidation}
              setSubmittedAddresses={setSubmittedAddresses}
              setShouldSave={setShouldSave}
            />
          </Suspense>
        </div>
      )}
    </div>
  );
};
