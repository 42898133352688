import { fabric } from 'fabric';
import { t } from 'i18next';
import { config } from '../../../regional-config';
import { CanvasDataTypes, getFontName, helperSettingsConfig } from '../../../utils';
import { getHorizontalCenterPoint, getVerticalCenterPoint } from '../card-editor-utils';
import { getEditableAreaInstructionsSize } from './get-editable-area-instructions-size';

const { userZoneInstructionsTopPosition } = helperSettingsConfig;
const { features, userZones } = config;

/**
 * Generate an Add Photo button for user zones
 *
 * @param zone - zone into which photo button will be added

 * @returns promise
 */
export const getUserZonePhotoButton = (zone: fabric.Object, isOneToMany: boolean): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(userZones.addPhotoIcon, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-photo-button`,
        hoverCursor: 'pointer',
        angle: zone.angle,
      });
      const addImageButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-photo-button-group`,
        hoverCursor: 'pointer',
        // If POD WAM enabled, PhotoButton positioned to the left (-100px) from our centered button
        // If POD WAM disabled, PhotoButton positioned to the left (-68px) from center point
        left: getHorizontalCenterPoint(zone) - (features?.isPodWamEnabled && !isOneToMany ? 100 : 68),
        top: getVerticalCenterPoint(zone),
        angle: zone.angle,

        data: {
          photoZoneId: zone.name,
          type: CanvasDataTypes.PhotoZoneButton,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addImageButton);
      });
    });
  });
};

/**
   * Generate an Add Text button for user zones
   *
   * @param zone - zone into which photo button will be added
   * @param isUK - used to determine which icon to display
   * @param addWamButton - used to determine whether the WAM button should be displayed
  
   * @returns promise
   */
export const getUserZoneTextButton = (zone: fabric.Object, isOneToMany: boolean): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(userZones.addTextIcon, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-text-button`,
        hoverCursor: 'pointer',
        angle: zone.angle,
      });
      const addTextButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-text-button-group`,
        hoverCursor: 'pointer',
        // If POD WAM enabled, button centered
        // If POD WAM disabled, button positioned to the right (68px) from center point
        left: getHorizontalCenterPoint(zone) + (features?.isPodWamEnabled && !isOneToMany ? 0 : 68),
        top: getVerticalCenterPoint(zone),
        angle: zone.angle,

        data: {
          photoZoneId: zone.name,
          type: CanvasDataTypes.UserZoneAddTextButton,
          removableOnSave: true,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addTextButton);
      });
    });
  });
};

/**
   * Generate an Add Text button for user zones
   *
   * @param zone - zone into which photo button will be added
   * @param isUK - used to determine which icon to display
  
   * @returns promise
   */
export const getUserZoneWamButton = (zone: fabric.Object): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(userZones.addWAMIcon, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-wam-button`,
        hoverCursor: 'pointer',
        angle: zone.angle,
      });
      const addWamButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-wam-button-group`,
        hoverCursor: 'pointer',
        // WAM Button positioned to the right (+100px) from our centered button
        left: getHorizontalCenterPoint(zone) + 100,
        top: getVerticalCenterPoint(zone),
        angle: zone.angle,

        data: {
          photoZoneId: zone.name,
          type: CanvasDataTypes.UserZoneAddWamButton,
          removableOnSave: true,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addWamButton);
      });
    });
  });
};

/**
   * Generate instructional text for user zones
   *
   * @param zone - zone into which instructional text will be added
  
   * @returns promise
   */
export const getUserZoneInstructions = (zone: fabric.Object): Promise<fabric.Text> => {
  return new Promise((resolve) => {
    const textElement = new fabric.Textbox(t('cardEditor.userZoneInstructions'), {
      originX: 'center',
      // Calculate instructions top position based on zone height
      // Try to calculate position of top as 33% of zone height. Fallback to userZoneInstructionsTopPosition (250)
      top: getVerticalCenterPoint(zone) - (zone && zone.height ? zone.height / 3 : userZoneInstructionsTopPosition),
      left: getHorizontalCenterPoint(zone), // Center horizontally
      width: zone.width ? zone.width - 20 : 0, // Reduce width to add 10px padding to left and right of textbox
      hasControls: false,
      editable: false,
      selectable: false,
      hoverCursor: 'default',
      textAlign: 'center',
      fontSize: getEditableAreaInstructionsSize(zone),
      fill: 'white',
      backgroundColor: '#333333',
      fontFamily: getFontName(120),
      data: {
        photoZoneId: zone.name,
        type: CanvasDataTypes.UserZoneInstructions,
        removableOnSave: true,
      },
      name: `${zone.name}-user-zone-add-instructions`,
    });
    document.fonts.ready.then(() => {
      resolve(textElement);
    });
  });
};
