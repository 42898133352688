import { fabric } from 'fabric';
import { CustomFabricObject } from '../../../global-types/canvas';

interface ScalingDimensions {
  originalScaleX: number;
  originalScaleY: number;
  originalImageLeft: number;
  originalImageTop: number;
}

interface CropAreaDimensions {
  cropLeft: number;
  cropTop: number;
  cropWidth: number;
  cropHeight: number;
  cropAngle: number;
}

export const getOriginalDimensions = (currentImage: CustomFabricObject): ScalingDimensions => {
  if (!currentImage || currentImage.left === undefined || currentImage.top === undefined) {
    throw new Error('Unable to get original dimensions: Image or its position is undefined');
  }

  return {
    originalScaleX: currentImage.scaleX ?? 1,
    originalScaleY: currentImage.scaleY ?? 1,
    originalImageLeft: currentImage.left,
    originalImageTop: currentImage.top,
  };
};

export const getCropAreaDimensions = (croppingArea: fabric.Rect): CropAreaDimensions => {
  if (!croppingArea) {
    throw new Error('Unable to get crop area dimensions: Cropping area is undefined');
  }

  return {
    cropLeft: croppingArea.left ?? 0,
    cropTop: croppingArea.top ?? 0,
    cropWidth: croppingArea.getScaledWidth(),
    cropHeight: croppingArea.getScaledHeight(),
    cropAngle: croppingArea.angle ?? 0,
  };
};

export const scaleImageToOriginal = (
  currentImage: CustomFabricObject,
  { originalScaleX, originalScaleY, originalImageLeft, originalImageTop }: ScalingDimensions,
): void => {
  if (!currentImage) {
    throw new Error('Unable to scale image: Image is undefined');
  }

  currentImage
    .set({
      scaleX: 1,
      scaleY: 1,
      left: originalImageLeft * (1 / originalScaleX),
      top: originalImageTop * (1 / originalScaleY),
    })
    .setCoords();
};

export const scaleCropArea = (
  croppingArea: fabric.Rect,
  originalDimensions: ScalingDimensions,
  cropDimensions: CropAreaDimensions,
): void => {
  if (!croppingArea) {
    throw new Error('Unable to scale crop area: Cropping area is undefined');
  }

  const { originalScaleX, originalScaleY } = originalDimensions;
  const { cropLeft, cropTop, cropWidth, cropHeight, cropAngle } = cropDimensions;

  // Scale factors for the cropping area
  const cropScaleX = 1 / originalScaleX;
  const cropScaleY = 1 / originalScaleY;

  // Calculate scaled dimensions
  const scaledCropLeft = cropLeft * cropScaleX;
  const scaledCropTop = cropTop * cropScaleY;

  // Calculate rotation offsets
  const radians = fabric.util.degreesToRadians(cropAngle);
  const offsetX = (cropWidth * cropScaleX) / 2;
  const offsetY = (cropHeight * cropScaleY) / 2;
  const rotatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
  const rotatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);

  croppingArea
    .set({
      width: cropWidth * cropScaleX,
      height: cropHeight * cropScaleY,
      left: scaledCropLeft + (rotatedOffsetX - offsetX),
      top: scaledCropTop + (rotatedOffsetY - offsetY),
      scaleX: 1,
      scaleY: 1,
      angle: cropAngle,
    })
    .setCoords();
};

export const restoreOriginalScale = (
  currentImage: CustomFabricObject,
  croppingArea: fabric.Rect,
  originalDimensions: ScalingDimensions,
  cropDimensions: CropAreaDimensions,
): void => {
  if (!currentImage || !croppingArea) {
    throw new Error('Unable to restore scale: Image or cropping area is undefined');
  }

  const { originalScaleX, originalScaleY, originalImageLeft, originalImageTop } = originalDimensions;
  const { cropWidth, cropHeight, cropLeft, cropTop } = cropDimensions;

  // Restore image scale
  currentImage
    .set({
      scaleX: originalScaleX,
      scaleY: originalScaleY,
      left: originalImageLeft,
      top: originalImageTop,
    })
    .setCoords();

  // Restore cropping area
  croppingArea
    .set({
      width: cropWidth,
      height: cropHeight,
      left: cropLeft,
      top: cropTop,
      scaleX: originalScaleX,
      scaleY: originalScaleY,
    })
    .setCoords();
};
