import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAppContext } from '../../../context/app-context/app-context';
import { setIsToasterOpen } from '../../../context/app-context/app-context-actions';
import { useInitializationDataContext } from '../../../context/data-context/data-context';
import { AddressRecipientUpdateForm } from '../../../global-types/addresses';
import { updateAddress } from '../../../services/address';
import { parseUpdateAddressForm } from '../utils/parse-update-address-form';

export const useUpdateAddress = (setRecipientAddressesDialogOpen: (isOpen: boolean) => void) => {
  const { t } = useTranslation();
  const { appDispatch } = useAppContext();
  const {
    initializedDataState: { data },
  } = useInitializationDataContext();

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const projectId = data?.project_id;

  const formValues = useForm<AddressRecipientUpdateForm>({
    mode: 'onChange',
    delayError: 250,
  });

  const handleSubmitRecipientAddress = async (address: AddressRecipientUpdateForm) => {
    if (projectId === undefined) {
      setIsToasterOpen(appDispatch, {
        variant: ToastVariants.Error,
        title: t('recipientAddresses.update.errorTitle'),
        children: t('recipientAddresses.update.errorMessage'),
      });
      return;
    }

    await updateAddress(projectId, address.address_id, parseUpdateAddressForm(address));
    setOpenEditDialog(false);
    setRecipientAddressesDialogOpen(true);
  };

  const handleCloseEditAddress = () => {
    setOpenEditDialog(false);
    setRecipientAddressesDialogOpen(true);
  };

  return {
    formValues,
    handleSubmitRecipientAddress,
    handleCloseEditAddress,
    dialogState: {
      openEditDialog,
      setOpenEditDialog,
    },
  };
};
