import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { updateAddresses, useInitializationDataContext } from '../../../context/data-context';
import { Address, AddressForm as AddressFormData, AddressTypes } from '../../../global-types';
import { useFeatureFlags } from '../../../hooks';
import { getFieldsFromAddressResponse } from '../../../utils';
import { buildOneToOneAddresses } from '../../../utils/build-one-to-one-addresses';
import { getDefaultAddressOptions } from '../utils';
import { useAddressConfirmationDialog } from './useAddressConfirmationDialog';
import { useEnvelopeAddresses } from './useEnvelopeAddresses';

export const useAddressSubmit = ({ setSubmittedAddresses, step, setStep, setShouldSave }) => {
  const { search } = useLocation();

  // Contexts
  const {
    initializedDataState: { addressData, isUK },
    initializationDataDispatch,
  } = useInitializationDataContext();

  const { trackClickNextToAddressSender } = useAnalyticsContext();

  // Get the addresses to fill based on country and envelope selection
  const addressTypesToFill = useEnvelopeAddresses();

  // * Address Confirmation Dialog
  const { closeAddressConfirmationDialog } = useAddressConfirmationDialog();

  const { IS_ADDRESS_VALIDATION_ENABLED } = useFeatureFlags();

  // Set the default address values to pre-populate the address form (if an address had been stored to the project)
  // Sets the country_code, address_type_code, and skip_usps_validation to the corresponding values
  const addressFormDefaultData = useMemo(() => {
    const currentAddressType = addressTypesToFill[+step];
    const storedAddress = getFieldsFromAddressResponse(addressData[`${currentAddressType}`]);
    const defaultAddressOptions = getDefaultAddressOptions(
      search,
      currentAddressType,
      isUK,
      IS_ADDRESS_VALIDATION_ENABLED,
    );
    return { ...storedAddress, ...defaultAddressOptions };
  }, [step, addressData.recipient, addressData.sender, addressTypesToFill, isUK]);

  const formValues = useForm<AddressFormData>({
    mode: 'onBlur',
    delayError: 250,
    defaultValues: addressFormDefaultData as AddressFormData,
  });

  // Function to be triggered when user wants to edit one of the addresses from address confirmation dialog
  const onEditAddress = useCallback(
    (stepToEdit: number) => {
      setStep(stepToEdit);
      closeAddressConfirmationDialog();
    },
    [closeAddressConfirmationDialog],
  );

  // * Address Form
  const handleAddressSubmit = (address: AddressFormData) => {
    buildOneToOneAddresses(address, setSubmittedAddresses);

    const currentAddressType = address.address_type_code === 'R' ? AddressTypes.RECIPIENT : AddressTypes.SENDER;
    // update state by saving the submitted address
    updateAddresses(initializationDataDispatch, {
      [currentAddressType]: { ...addressData[`${currentAddressType}`], ...(address as Address) },
    });

    const totalSteps = addressTypesToFill.length;
    // move to the next step or start address validation
    if (step < totalSteps - 1) {
      setStep((currentStep) => currentStep + 1);
      trackClickNextToAddressSender();
      return;
    }
    setShouldSave(true);
  };

  return {
    addressFormDefaultData,
    formValues,
    onEditAddress,
    handleAddressSubmit,
  };
};
